.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 4px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.15s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #007bff;
  background: #007bff;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #0062cc;
  background: #0062cc;
  color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ced4da;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #0062cc;
  background: #0062cc;
  color: #ffffff;
}
.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #dc3545;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #efefef;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #007bff;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #efefef;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #0062cc;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #ffffff;
}
